import { ICasinoFavoriteResponse, ICasinoGame } from "@finbackoffice/clientbff-client";
import {
    createContext,
    FC,
    RefObject,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    AuthContext,
    CasinoFavoritesContext,
    CasinoScopesMap,
    RuntimeConfigContext,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import {
    CasinoGameTypeEnum,
    CasinoWidgetImgSrcEnum,
    formatAppNameText,
    sortByCasinoTagsOrder,
} from "@finbackoffice/fe-core";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import { IModalForwardRefProps } from "components/base/modal/Modal";
import { CasinoGameImgFolderType, getCasinoGameImgSrc } from "utils/helpers";
import { CasinoToolsContext } from "contexts";
import Compact from "./layouts/compact/Compact";
import Rounded from "./layouts/rounded/Rounded";
import Squared from "./layouts/squared/Squared";

export const CasinoGameTagFlags = [
    CasinoGameTag.New,
    CasinoGameTag.Top,
    CasinoGameTag.Hot,
    CasinoGameTag.Popular,
];

type IProps = {
    game: ICasinoGame | ICasinoFavoriteResponse;
    disableLocalThumbnail?: boolean;
    showName?: boolean;
    hideTag?: CasinoGameTag[];
    fallbackSrc?: string;
    onReady?: () => void;
    hidePlayButtons?: boolean;
    layout?: "default" | "rounded" | "squared";
    backImg?: boolean;
    imgSrc?: CasinoWidgetImgSrcEnum;
    imgFolder?: CasinoGameImgFolderType;
};

type ICasinoGameItemContext = IProps & {
    gameId: string;
    handlePlayNow: () => void;
    handlePlayForFun: () => void;
    gameName: string;
    isUserLoggedIn: boolean;
    handleFavoriteClick: () => void;
    isFavorite: boolean;
    isSlots: boolean;
    tagsMemo: CasinoGameTag[];
    gameWithVideoThumbnailModalRef: RefObject<IModalForwardRefProps>;
    isVideo: boolean;
    handleVideoError: () => void;
    gameImgSrc: string;
    isFallbackSrcActive: boolean;
    onFallbackHandler: () => void;
};

export const CasinoGameItemContext = createContext<ICasinoGameItemContext>(null as any);

const CasinoGameItem: FC<IProps> = ({
    game,
    disableLocalThumbnail,
    showName = false,
    hideTag,
    fallbackSrc,
    onReady,
    hidePlayButtons,
    layout,
    backImg,
    imgSrc,
    imgFolder,
}) => {
    const runtimeConfig = useContext(RuntimeConfigContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { createCasinoFavorite, deleteCasinoFavorite, favoriteGamesState } =
        useContext(CasinoFavoritesContext);
    const isSlots = CasinoScopesMap.slots.includes(game.type as CasinoGameTypeEnum);
    const { t } = useTranslation(`${TranslationScopes.Casino}_${isSlots ? "slots" : "livecasino"}`);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { handlePlayNow, handlePlayForFun } = useContext(CasinoToolsContext);
    const [loading, setLoading] = useState(false);
    const [hasErr, setHasErr] = useState(false);
    const [isFallbackSrcActive, setIsFallbackSrcActive] = useState(false);
    const gameWithVideoThumbnailModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const gameId = useMemo(
        () => (typeof game.id === "string" ? game.id : (game as ICasinoFavoriteResponse).game_id),
        [game],
    );

    const gameImgSrc = useMemo(() => {
        if (imgSrc && game[imgSrc]) {
            return game[imgSrc];
        } else {
            return disableLocalThumbnail
                ? game.image_filled
                : getCasinoGameImgSrc(
                      runtimeConfig,
                      gameId,
                      game.type as CasinoGameTypeEnum,
                      game.tags,
                      imgFolder ? { folder: imgFolder } : undefined,
                  );
        }
    }, [imgSrc, game, disableLocalThumbnail, runtimeConfig, gameId, imgFolder]);

    const fallbackSrcMemo = useMemo(() => {
        const formatedAppName = formatAppNameText(COMMON_SITE_CONFIGS.appName);
        const hasAppNameInSrc = gameImgSrc.includes(formatedAppName);
        if (isFallbackSrcActive && hasAppNameInSrc) {
            return fallbackSrc;
        }
        return hasAppNameInSrc ? gameImgSrc.replace(`_${formatedAppName}`, "") : fallbackSrc;
    }, [fallbackSrc, gameImgSrc, isFallbackSrcActive, COMMON_SITE_CONFIGS.appName]);

    const gameNameTid = gameId.replaceAll("-", "_").replace("_mobile", "");
    const gameName = useMemo(() => {
        if (isSlots) {
            return game.name;
        } else if (t(gameNameTid) !== gameNameTid) {
            return t(gameNameTid);
        }
        return game.name;
    }, [game.name, gameNameTid, isSlots, t]);
    const isFavorite = useMemo(
        () =>
            Boolean(favoriteGamesState.games.find((favGame) => favGame.game_id === gameId)) ??
            false,
        [favoriteGamesState.games, gameId],
    );

    const isVideo = gameImgSrc.includes("mp4");

    const handleVideoError = useCallback(() => {
        setHasErr(true);
    }, []);

    const handleFavoriteClick = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            if (isFavorite) {
                await deleteCasinoFavorite(gameId);
            } else {
                await createCasinoFavorite(gameId);
            }
            setLoading(false);
        }
    }, [createCasinoFavorite, deleteCasinoFavorite, gameId, isFavorite, loading]);

    const onFallbackHandler = useCallback(() => {
        setIsFallbackSrcActive(true);
    }, []);

    const handlePlayNowMemo = useCallback(
        () => handlePlayNow(gameId, isSlots),
        [gameId, handlePlayNow, isSlots],
    );

    const handlePlayForFunMemo = useCallback(
        () => handlePlayForFun(gameId, isSlots),
        [gameId, handlePlayForFun, isSlots],
    );

    const tagsMemo = useMemo(() => {
        return CasinoGameTagFlags.filter(
            (tag) => game.tags.includes(tag) && !hideTag?.includes(tag),
        ).sort(sortByCasinoTagsOrder);
    }, [game.tags, hideTag]);

    const renderLayout = useMemo(() => {
        if (layout === "rounded") {
            return <Rounded />;
        }
        if (layout === "squared") {
            return <Squared />;
        }
        return <Compact />;
    }, [layout]);

    const value = useMemo(
        () => ({
            game,
            handlePlayNow: handlePlayNowMemo,
            handlePlayForFun: handlePlayForFunMemo,
            gameName,
            isUserLoggedIn,
            isFavorite,
            handleFavoriteClick,
            showName,
            hideTag,
            fallbackSrc: fallbackSrcMemo,
            onReady,
            hidePlayButtons,
            isSlots,
            tagsMemo,
            gameWithVideoThumbnailModalRef,
            isVideo,
            gameImgSrc,
            isFallbackSrcActive,
            onFallbackHandler,
            handleVideoError,
            gameId,
            backImg,
        }),
        [
            game,
            handlePlayNowMemo,
            gameName,
            isUserLoggedIn,
            isFavorite,
            handleFavoriteClick,
            handlePlayForFunMemo,
            showName,
            hideTag,
            fallbackSrcMemo,
            onReady,
            hidePlayButtons,
            isSlots,
            tagsMemo,
            isVideo,
            gameImgSrc,
            isFallbackSrcActive,
            onFallbackHandler,
            gameId,
            handleVideoError,
            backImg,
        ],
    );

    if (hasErr) {
        return null;
    }

    return (
        <CasinoGameItemContext.Provider value={value}>
            {renderLayout}
        </CasinoGameItemContext.Provider>
    );
};
export default CasinoGameItem;
